<template>
    <div>
        <el-form
            v-loading="dataLoading"
            :model="formData"
            :rules="rules"
            ref="formData"
            label-position="top"
            size="medium"
        >
            <div class="page-group-title"><span>逝者信息</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="name" label="逝者姓名:">
                        <el-input v-model.trim="formData.name" placeholder="请输入逝者姓名" maxlength="20"></el-input>
                    </el-form-item>
                </el-col>
                <el-col v-if="id" :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="status" label="状态:">
                        <el-radio-group v-model="formData.status">
                            <el-radio :label="1">正常</el-radio>
                            <el-radio :label="0">禁用</el-radio>
                            <el-radio :label="-1">删除</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="sex" label="逝者性别:">
                        <el-select v-model="formData.sex" placeholder="选择逝者性别">
                            <el-option :value="1" label="男"></el-option>
                            <el-option :value="2" label="女"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="birthday" label="逝者生辰:">
                        <el-date-picker
                        v-model="formData.birthday"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="age" label="逝者年龄:">
                        <el-input-number v-model="formData.age" :precision="0" :controls="false" :min="0" :max="99999999" placeholder="请输入年龄"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="dieday" label="逝世时间:">
                        <el-date-picker
                        v-model="formData.dieday"
                        placeholder="选择时间"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="area" label="所在地区:">
                        <el-cascader v-model="formData.area" :options="areaList" :props="areaSeting" @change="changeArea"></el-cascader>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="address" label="详细地址:">
                        <el-input v-model.trim="formData.address" placeholder="请输入详细地址" maxlength="200"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="businessId" label="商家:">
                        <el-select v-model="formData.businessId" placeholder="输入商家名称搜索" filterable remote :remote-method="getBiz" @focus="getBiz" :loading="bizLoading" :disabled="id ? true : false" clearable>
                            <el-option v-for="(item,index) in bizList" :key="index" :value="item.id" :label="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="page-group-title"><span>逝者家属</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="familyName" label="家属姓名:">
                        <el-input v-model.trim="formData.familyName" placeholder="请输入家属姓名" maxlength="20"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="familyRel" label="家属关系:">
                        <el-input v-model.trim="formData.familyRel" placeholder="请输入家属关系" maxlength="20"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="telephone" label="联系电话:">
                        <el-input v-model.trim="formData.telephone" placeholder="请输入家属联系电话" maxlength="11"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-divider></el-divider>
        <div class="form-btns">
            <el-button @click="goBack">返回</el-button>
            <el-button type="primary"  @click="onSubmit" :loading="btnLoading">保存</el-button>
        </div>
    </div>
</template>
<script>
import {allarea} from '@/assets/js/commonApi.js';
const defaultFormData = {
    "address": "",
	"age": undefined,
	"birthday": "",
	"businessId": "",
    "businessName": "",
	"cityCode": "",
	"countyCode": "",
	"dieday": "",
	"familyName": "",
	"familyRel": "",
	"id": "",
	"name": "",
	"provinceCode": "",
	"sex": 1,
	"status": 1,
	"telephone": ""
}
export default {
    props:{
        id:{
            type: [String, Number],
            default: ''
        }
    },
    data(){
        return{
            redirect: this.$route.query.redirect || '/customer/list',
            btnLoading: false,
            dataLoading: false,
            formData: {...defaultFormData},
            rules:{
                name: [{required: true, message: '请输入逝者姓名'}],
                age: [{required: true, message: '请输入逝者年龄'}],
                birthday: [{required: true, message: '请输入逝者生辰'}],
                dieday: [{required: true, message: '请输入逝世时间'}],
                area: [{type: 'array', required: true, message: '请选择所在区域'}],
                address: [{required: true, message: '请填写详细地址'}],
                familyName: [{required: true, message: '请输入家属姓名'}],
                familyRel: [{required: true, message: '请输入家属关系'}],
                telephone: [{required: true, message: '请输入家属联系电话'},{ validator: this.$utils.validatePhone }],
                businessId: [{required: true, message: '请选择商家'}],
            },
            reFresh:false,
            areaList:[],
            areaSeting: {
                value: "code",
                label: "name",
                children: "child"
            },
            pickerOptions:{
                disabledDate: (date) => {
                    return date > new Date();
                }
            },
            bizList:[],
            bizLoading: false
        }
    },
    created(){
        this.getAllArea();
    },
    mounted(){
        if( this.id ){
            this.getDataDetail();
        }else{
            this.reFresh = true;
        }
    },
    methods:{
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
        //地域信息，省、市、县，树形结构
        async getAllArea(){
            const res = await allarea();
            const {state,result} = res.data;
            if(state && result){
                this.areaList = [...result];
            }
        },
        //选择区域
        changeArea(e){
            this.formData.provinceCode = e[0];
            this.formData.cityCode = e[1];
            this.formData.countyCode = e[2];
        },
        //获取所有商家
        getBiz(query){console.log(query)
            // if(query === "") return;
            this.bizLoading = true
            this.$request({
                url: "/api/ht/biz/selectlist",
                params: {keyword:query.isTrusted ? '' : query},
            })
            .then((res) => {
                const { state, errmsg, result } = res.data;
                if (state && result) {
                    this.bizList = result;
                } else {
                    this.$message.error(errmsg || "请求失败");
                }
            })
            .finally(() => {
                this.bizLoading = false;
            });
        },
        //获取编辑详情
        getDataDetail(){
            this.dataLoading = true;
            this.$request({
                url: '/api/ht/customer/info',
                method: "POST",
                params: {customerId: this.id},
            })
                .then((res) => {
                    //处理成功回调
                    const { state, errmsg, result } = res.data;
                    if (state && state == 1) {
                        this.formData = {
                            ...defaultFormData,
                            ...result,
                            area: [result.provinceCode || '',result.cityCode || '', result.countyCode || '']
                        }
                        this.bizList = [{id: result.businessId, name: result.businessName}]
                    } else {
                        return this.$message.error(errmsg || "获取详情失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取详情失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                    this.reFresh = true;
                });
        },
        onSubmit(){
            this.$refs["formData"].validate((valid) => {
                const _url = this.id ? '/api/ht/customer/edit' : '/api/ht/customer/add'
                if (valid) {
                    this.btnLoading = true;
                    this.$request({
                        url: _url,
                        method: "POST",
                        data: {
                            ...this.formData,
                        },
                    })
                        .then((res) => {
                            //处理成功回调
                            const { state, msg } = res.data;
                            if (state && state == 1) {
                                this.goBack();
                            } else {
                                return this.$message.error(msg || "操作失败");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }
            });
        }
    }
}
</script>